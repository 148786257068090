var a = true;
$(function () {
    $('#content, #content p, #content pre, #content h1, #content h2, #content h3, #content h4, #content h5, #content h6, #content div').contents().filter(function () {
        // 8 = Node.COMMENT_NODE
        return this.nodeType == 8;
    }).each(function (index, value) {
//        console.log("usao...");
        var copy = $(value).parent().clone();
        copy.empty();
        $(value).parent().contents().each(function (index, value) {
            if (value.nodeType != 8) {
                copy.append($(value));
            } else {
                if (value.nodeValue.startsWith('<box')) {

                    if (!isEmpty(copy)) {
                        $(value).parent().before(copy);
                    }
                    copy = $(value).parent().clone();
                    copy.empty();

                    var valueCopy = $(value).clone();
                    $(value).parent().before(valueCopy);

                    var tag = value.nodeValue.replace('>', '').split(' ');

                    var cls = tag[1];
                    var id = tag[2];
                    var type = tag[3];

                    var params = {};
                    params.boxId = id;
                    params.autoplay = false;
                    params.pClass = cls;

                    if (type !== null) {

                        $.ajax({
                            url: "/boxes/" + type + "Box.jsp",
                            type: "POST",
                            data: params,
                            success: function (data, textStatus, jqXHR) {
                                $(valueCopy).replaceWith(data);
                            },
                            complete: function (jqXHR, textStatus) {
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                            }
                        });
                    }
                }
            }
        });
        if (!isEmpty(copy)) {
            $(value).parent().before(copy);
        }
        $(value).parent().remove();
    });
});
function isEmpty(el) {
    return !$.trim(el.html());
}