$(document).ready(function () {
    $("li.level1").hover(
            function () {
                $(this).addClass("hoverL1");
            }, function () {
        $(this).removeClass("hoverL1");
    });
    $("div.navL1 li").hover(
            function () {
                $(this).addClass("hoverL2");
            }, function () {
        $(this).removeClass("hoverL2");
    });
    setTimeout(function () {
        $('.body .embed-box iframe[src*="youtube.com"]').wrap("<div class='youtubeWrapper'></div>");
        $('.body .embed-box iframe[src*="www.facebook.com/plugins/video.php"]').wrap("<div class='facebookWrapper'></div>");
        $('.body .embed-box iframe[src*="youtube-nocookie.com"]').wrap("<div class='youtubeWrapper'></div>");
    }, 1000);
    /* search open */
    $(".searchForm-button").click(function () {
        /*$(".searchForm-container").toggleClass("open");*/
        $(".searchForm-container").addClass("open");
    });
    /* search close */
    $(".searchClose").click(function () {
        $(".searchForm-container").removeClass("open");
    });
    /*  responsive nav */
    $(".responsive-nav-button").click(function () {
        $(".responsive-nav-wrapper").addClass("open");
    });
    $(".nav-close").click(function () {
        $(".responsive-nav-wrapper").removeClass("open");
    });

    $(".close-div").click(function () {
        $(".responsive-nav-wrapper").removeClass("open");
    });
    $(".responsive-menu.primary li.depth1 i").on("click", function (event) {
        $(event.target).closest("li").toggleClass("open-dropdown");
    });
    $(".responsive-menu.secundary li.depth1 i").on("click", function (event) {
        $(event.target).closest("li").toggleClass("open-dropdown");
    });
    /* stop scroll nav */
    var el = document.querySelector('.stop-scroll-nav.active');
    window.addEventListener('scroll', function (e) {
        if (el) {
            var elementPos = el.getBoundingClientRect();
            var elBot = elementPos.bottom;
            var elHeight = elementPos.height;
            var curTop = window.pageYOffset || document.documentElement.scrollTop;
            if (elBot <= window.innerHeight - elHeight) {
                window.scrollTo(0, curTop - (window.innerHeight - elBot));
            }
        }
    });
});

//top-left secondary-navigation
//$(document).ready(function () {
//    $('.navBtn').click(function () {
//        $('.navBtn').toggleClass('active');
//        $('.topLeft-Nav').toggleClass('active');
//        $('#secondaryNavigation').toggleClass('active');
//    });
//});

/* popup window */
(function ($) {
    $.fn.popupWindow = function (instanceSettings) {
        return this.each(function () {
            $(this).click(function () {
                $.fn.popupWindow.defaultSettings = {
                    centerBrowser: 0, // center window over browser window? {1 (YES) or 0 (NO)}. overrides top and left
                    centerScreen: 0, // center window over entire screen? {1 (YES) or 0 (NO)}. overrides top and left
                    height: 500, // sets the height in pixels of the window.
                    left: 0, // left position when the window appears.
                    location: 0, // determines whether the address bar is displayed {1 (YES) or 0 (NO)}.
                    menubar: 0, // determines whether the menu bar is displayed {1 (YES) or 0 (NO)}.
                    resizable: 0, // whether the window can be resized {1 (YES) or 0 (NO)}. Can also be overloaded using resizable.
                    scrollbars: 0, // determines whether scrollbars appear on the window {1 (YES) or 0 (NO)}.
                    status: 0, // whether a status line appears at the bottom of the window {1 (YES) or 0 (NO)}.
                    width: 500, // sets the width in pixels of the window.
                    windowName: null, // name of window set from the name attribute of the element that invokes the click
                    windowURL: null, // url used for the popup
                    top: 0, // top position when the window appears.
                    toolbar: 0 // determines whether a toolbar (includes the forward and back buttons) is displayed {1 (YES) or 0 (NO)}.
                };
                settings = $.extend({}, $.fn.popupWindow.defaultSettings, instanceSettings || {});
                var windowFeatures = 'height=' + settings.height +
                        ',width=' + settings.width +
                        ',toolbar=' + settings.toolbar +
                        ',scrollbars=' + settings.scrollbars +
                        ',status=' + settings.status +
                        ',resizable=' + settings.resizable +
                        ',location=' + settings.location +
                        ',menuBar=' + settings.menubar;
                settings.windowName = this.name || settings.windowName;
                settings.windowURL = this.href || settings.windowURL;
                var centeredY, centeredX;
                if (settings.centerBrowser) {
                    if ($.browser.msie) {//hacked together for IE browsers
                        centeredY = (window.screenTop - 120) + ((((document.documentElement.clientHeight + 120) / 2) - (settings.height / 2)));
                        centeredX = window.screenLeft + ((((document.body.offsetWidth + 20) / 2) - (settings.width / 2)));
                    } else {
                        centeredY = window.screenY + (((window.outerHeight / 2) - (settings.height / 2)));
                        centeredX = window.screenX + (((window.outerWidth / 2) - (settings.width / 2)));
                    }
                    window.open(settings.windowURL, settings.windowName, windowFeatures + ',left=' + centeredX + ',top=' + centeredY).focus();
                } else if (settings.centerScreen) {
                    centeredY = (screen.height - settings.height) / 2;
                    centeredX = (screen.width - settings.width) / 2;
                    window.open(settings.windowURL, settings.windowName, windowFeatures + ',left=' + centeredX + ',top=' + centeredY).focus();
                } else {
                    window.open(settings.windowURL, settings.windowName, windowFeatures + ',left=' + settings.left + ',top=' + settings.top).focus();
                }
                return false;
            });
        });
    };
})(jQuery);
/* scroll content */
(function ($) {
    function fireEvent(opts, name, self, arg) {
        var fn = opts[name];
        if ($.isFunction(fn)) {
            try {
                return fn.call(self, arg);
            } catch (error) {
                if (opts.alert) {
                    alert("Error calling scrollable." + name + ": " + error);
                } else {
                    throw error;
                }
                return false;
            }
        }
        return true;
    }
    var current = null;
    function Scrollable(root, conf) {
        var self = this;
        if (!current) {
            current = self;
        }
        var horizontal = !conf.vertical;
        var wrap = $(conf.items, root);
        var index = 0;
        var navi = root.siblings(conf.navi).eq(0);
        var prev = root.siblings(conf.prev).eq(0);
        var next = root.siblings(conf.next).eq(0);
        var prevPage = root.siblings(conf.prevPage).eq(0);
        var nextPage = root.siblings(conf.nextPage).eq(0);
        $.extend(self, {
            getVersion: function () {
                return[1, 0, 1];
            },
            getIndex: function () {
                return index;
            },
            getConf: function () {
                return conf;
            },
            getSize: function () {
                return self.getItems().size();
            },
            getPageAmount: function () {
                return Math.ceil(this.getSize() / conf.size);
            },
            getPageIndex: function () {
                return Math.ceil(index / conf.size);
            },
            getRoot: function () {
                return root;
            },
            getItemWrap: function () {
                return wrap;
            },
            getItems: function () {
                return wrap.children();
            },
            seekTo: function (i, time, fn) {
                time = time || conf.speed;
                if ($.isFunction(time)) {
                    fn = time;
                    time = conf.speed;
                }
                if (i < 0) {
                    i = 0;
                }
                if (i > self.getSize() - conf.size) {
                    return self;
                }
                var item = self.getItems().eq(i);
                if (!item.length) {
                    return self;
                }
                if (fireEvent(conf, "onBeforeSeek", self, i) === false) {
                    return self;
                }
                if (horizontal) {
                    var left = -(item.outerWidth(true) * i);
                    wrap.animate({
                        left: left
                    }, time, conf.easing, fn ? function () {
                        fn.call(self);
                    } : null);
                } else {
                    var top = -(item.outerHeight(true) * i);
                    wrap.animate({
                        top: top
                    }, time, conf.easing, fn ? function () {
                        fn.call(self);
                    } : null);
                }
                if (navi.length) {
                    var klass = conf.activeClass;
                    var page = Math.ceil(i / conf.size);
                    page = Math.min(page, navi.children().length - 1);
                    navi.children().removeClass(klass).eq(page).addClass(klass);
                }
                if (i === 0) {
                    prev.add(prevPage).addClass(conf.disabledClass);
                } else {
                    prev.add(prevPage).removeClass(conf.disabledClass);
                }
                if (i >= self.getSize() - conf.size) {
                    next.add(nextPage).addClass(conf.disabledClass);
                } else {
                    next.add(nextPage).removeClass(conf.disabledClass);
                }
                current = self;
                index = i;
                fireEvent(conf, "onSeek", self, i);
                return self;
            },
            move: function (offset, time, fn) {
                var to = index + offset;
                if (conf.loop && to > (self.getSize() - conf.size)) {
                    to = 0;
                }
                return this.seekTo(to, time, fn);
            },
            next: function (time, fn) {
                return this.move(1, time, fn);
            },
            prev: function (time, fn) {
                return this.move(-1, time, fn);
            },
            movePage: function (offset, time, fn) {
                return this.move(conf.size * offset, time, fn);
            },
            setPage: function (page, time, fn) {
                var size = conf.size;
                var index = size * page;
                var lastPage = index + size >= this.getSize();
                if (lastPage) {
                    index = this.getSize() - conf.size;
                }
                return this.seekTo(index, time, fn);
            },
            prevPage: function (time, fn) {
                return this.setPage(this.getPageIndex() - 1, time, fn);
            },
            nextPage: function (time, fn) {
                return this.setPage(this.getPageIndex() + 1, time, fn);
            },
            begin: function (time, fn) {
                return this.seekTo(0, time, fn);
            },
            end: function (time, fn) {
                return this.seekTo(this.getSize() - conf.size, time, fn);
            },
            reload: function () {
                return load();
            },
            click: function (index, time, fn) {
                var item = self.getItems().eq(index);
                var klass = conf.activeClass;
                if (!item.hasClass(klass) && (index >= 0 || index < this.getSize())) {
                    self.getItems().removeClass(klass);
                    item.addClass(klass);
                    var delta = Math.floor(conf.size / 2);
                    var to = index - delta;
                    if (to > self.getSize() - conf.size) {
                        to--;
                    }
                    if (to !== index) {
                        return this.seekTo(to, time, fn);
                    }
                }
                return self;
            }
        });
        if ($.isFunction($.fn.mousewheel)) {
            root.bind("mousewheel.scrollable", function (e, delta) {
                var step = $.browser.opera ? 1 : -1;
                self.move(delta > 0 ? step : -step, 50);
                return false;
            });
        }
        prev.addClass(conf.disabledClass).click(function () {
            self.prev();
        });
        next.click(function () {
            self.next();
        });
        nextPage.click(function () {
            self.nextPage();
        });
        prevPage.addClass(conf.disabledClass).click(function () {
            self.prevPage();
        });
        if (conf.keyboard) {
            $(window).unbind("keypress.scrollable").bind("keypress.scrollable", function (evt) {
                var el = current;
                if (!el) {
                    return;
                }
                if (horizontal && (evt.keyCode == 37 || evt.keyCode == 39)) {
                    el.move(evt.keyCode == 37 ? -1 : 1);
                    return evt.preventDefault();
                }
                if (!horizontal && (evt.keyCode == 38 || evt.keyCode == 40)) {
                    el.move(evt.keyCode == 38 ? -1 : 1);
                    return evt.preventDefault();
                }
                return true;
            });
        }
        function load() {
            navi.each(function () {
                var nav = $(this);
                if (nav.is(":empty") || nav.data("me") == self) {
                    nav.empty();
                    nav.data("me", self);
                    for (var i = 0; i < self.getPageAmount(); i++) {
                        var item = $("<" + conf.naviItem + "/>").attr("href", i).click(function (e) {
                            var el = $(this);
                            el.parent().children().removeClass(conf.activeClass);
                            el.addClass(conf.activeClass);
                            self.setPage(el.attr("href"));
                            return e.preventDefault();
                        });
                        if (i === 0) {
                            item.addClass(conf.activeClass);
                        }
                        nav.append(item);
                    }
                } else {
                    var els = nav.children();
                    els.each(function (i) {
                        var item = $(this);
                        item.attr("href", i);
                        if (i === 0) {
                            item.addClass(conf.activeClass);
                        }
                        item.click(function () {
                            nav.find("." + conf.activeClass).removeClass(conf.activeClass);
                            item.addClass(conf.activeClass);
                            self.setPage(item.attr("href"));
                        });
                    });
                }
            });
            if (conf.clickable) {
                self.getItems().each(function (index, arg) {
                    var el = $(this);
                    if (!el.data("set")) {
                        el.bind("click.scrollable", function () {
                            self.click(index);
                        });
                        el.data("set", true);
                    }
                });
            }
            if (conf.hoverClass) {
                self.getItems().hover(function () {
                    $(this).addClass(conf.hoverClass);
                }, function () {
                    $(this).removeClass(conf.hoverClass);
                });
            }
            return self;
        }
        load();
        var timer = null;
        function setTimer() {
            timer = setInterval(function () {
                self.next();
            }, conf.interval);
        }
        if (conf.interval > 0) {
            root.hover(function () {
                clearInterval(timer);
            }, function () {
                setTimer();
            });
            setTimer();
        }
    }
    jQuery.prototype.scrollable = function (conf) {
        var api = this.eq(typeof conf == 'number' ? conf : 0).data("scrollable");
        if (api) {
            return api;
        }
        var opts = {
            size: 5,
            vertical: false,
            clickable: true,
            loop: false,
            interval: 0,
            speed: 400,
            keyboard: true,
            activeClass: 'active',
            disabledClass: 'disabled',
            hoverClass: null,
            easing: 'swing',
            items: '.items',
            prev: '.prev',
            next: '.next',
            prevPage: '.prevPage',
            nextPage: '.nextPage',
            navi: '.navigator',
            naviItem: 'a',
            onBeforeSeek: null,
            onSeek: null,
            alert: true
        };
        $.extend(opts, conf);
        this.each(function () {
            var el = new Scrollable($(this), opts);
            $(this).data("scrollable", el);
        });
        return this;
    };
})(jQuery);
/* used on every page! */
/* call to popup window */
function pollValidate(warningMessage) {
    var pollValid = false;
    $('.pollHolder .radioButton').each(function (index, value) {
        if (value.checked)
            pollValid = true;
    });
    if (!pollValid)
        alert(warningMessage);
    return pollValid;
}
/* normalni popup prozor */
function openPopup(url, width, height) {
    window.open(url, "popup_id", "location=no,scrollbars=no,resizeble=no,status=no,width=" + width + ",height=" + height);
    return false;
}
/* breaking news */
var starteddd = false;
$(function () {
    //$("ul#ticker02").liScroll({travelocity: 0.03});
    var nnn = $(".breakingDiv").length;
    var iiiii = 0;
    var pauseddd = false;
    var refreshIntervalId;
    if (nnn > 0 && !starteddd) {
        starteddd = true;
        refreshIntervalId = setInterval(function () {
            if (!pauseddd) {
                if (iiiii + 1 < nnn) {
                    iiiii++;
                } else {
                    iiiii = 0;
                }
                //                    alert($(".breakingDiv:visible").attr("id"));
                $(".breakingDiv:visible").slideUp(500);
                $("#breakingDiv" + iiiii).slideDown(1000);
            }
        }, 3000);
    }
    $(".breakingDiv").hover(function () {
        pauseddd = true;
        //clearInterval(refreshIntervalId);
    }, function () {
        pauseddd = false;
        //starteddd=false;
    });
    $(window).focus(function () {
        pauseddd = false;
    });
    $(window).blur(function () {
        pauseddd = true;
    });
});
//faq
var allPanels = $('#accordion > div > div.accordionContent').hide();
var allAccordionTitles = $('#accordion > div > h3');
var firstTitle = $('#accordion > div > h3').first();
var firstContent = $('#accordion > div > div.accordionContent').first();
firstTitle.addClass('active');
firstContent.slideDown();
$('#accordion > div > h3').click(function () {
    allPanels.slideUp();
    if ($(this).hasClass('active')) {
        $(this).next().slideUp();
        $(this).removeClass('active');
    } else {
        allAccordionTitles.removeClass('active');
        $(this).addClass('active');
        $(this).next().slideDown();
    }
//    return false;
});
    